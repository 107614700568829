/* @import url("https://fonts.googleapis.com/css?family=Overpass");
* {
  box-sizing: border-box;
  font-family: "Overpass", sans-serif;
} */


.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 32px;
  width: 480px;
  padding: 8px;
  align-items: center;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  /* &:focus-within {
    border: 1px solid #0052cc;
  } */
  
}

input {
    flex: 1;
    border: none;
    height: 32px;
    font-size: 14px;
    padding: 4px;
    /* &:focus {
      outline: transparent;
    } */
  }

#tags {
  display: flex;
  flex-wrap: wrap;
  /* padding-top: 3px; */
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;

  
}

.tag-title {
    margin-top: 3px;
}

.tag-close-icon {
display: block;
width: 16px;
height: 16px;
line-height: 16px;
text-align: center;
font-size: 14px;
margin-left: 8px;
color: #0052cc;
border-radius: 50%;
background: #fff;
cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}