@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@layer components{
  .btn{
    @apply bg-primary text-white hover:bg-gray-700 border border-primary transition-all text-lg rounded-sm px-6 py-2 ease-out duration-75 delay-75
  }
  .input-form{
    @apply border border-primary active:border-0 active:ring-0 active:rounded-sm bg-white  rounded-sm w-full p-2
  }
}

@layer utilities{
    @layer responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}